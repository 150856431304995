<template>
  <div class="channels__enable-settings">
    <div class="channels__warning-red campaigns">
      <span>{{ enableSettings["apply-changes"] }}</span>
      <span class="channels__warning-bold">
        {{ enableSettings["only-channel"] }}
      </span>
      <span>{{ enableSettings["press-save"] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    enableSettings() {
      return this.$t('channels-settings.enable-settings');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
